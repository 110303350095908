export const useHotLinkGet = () => useState("use_hot_link_get", () => false)
export const useHotLinkOpen = () => useState("use_link_open", () => false)
export const useHotLinkUrl = () => useState("use_link_url", () => "")

export const useLandingHotActivity = () => {
  const link_open = computed(() => {
    return useHotLinkOpen().value
  })

  const link_url = computed(() => {
    return useHotLinkUrl().value
  })

  const getHotActivityLinkData = async () => {
    const linkGet = useHotLinkGet()
    const linkOpen = useHotLinkOpen()
    const linkUrl = useHotLinkUrl()
    try {
      if (linkUrl.value) {
        return
      }
      const { open, url } = await getHotPageOpen()
      linkGet.value = true
      linkOpen.value = open
      linkUrl.value = url
    } catch (e) {}
  }

  getHotActivityLinkData()

  return {
    link_url,
    link_open
  }
}
